import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';


// Componants
import LayOut from './Componants/Shared/LayOut';
import Notfound from './Componants/Shared/Notfound';
import Home from './Componants/Website/Home';
import AboutUs from './Componants/Website/AboutUs';
import ContactUS from './Componants/Website/ContactUS';
import Login from './Componants/Account/Login';
import Courses from './Componants/Materials/Courses';
import Register from './Componants/Account/Register';
import Profile from './Componants/Account/Profile';
// import Globalpage from "./Componants/Shared/Globalpage";
import Course from './Componants/Materials/Course';
import Terms from './Componants/Website/Terms';
import Policy from './Componants/Website/Policy';
import Chapter from './Componants/Materials/Chapter';
import Cart from './Componants/Checkout/Cart';
import PaymentResult from './Componants/Checkout/PaymentResult';
import Universities from './Componants/Materials/Universities';

// Providers
import { UserProvider } from './Contexts/UserContext';
import { CartProvider } from './Contexts/CartContext';
import { LanguageProvider } from './Contexts/LanguageContext';
import { LoaderProvider } from './Contexts/LoaderContext';
import { ApiDataProvider } from './Contexts/ApiDataContext';
import MyOrders from './Componants/Account/MyOrders';
import MyCourses from './Componants/Account/MyCourses';
import MyInstallments from './Componants/Account/MyInstallments';
import MyProjects from './Componants/Account/MyProjects';
import Project from './Componants/Materials/Project';
import SubmissionFiles from './Componants/Materials/SubmissionFiles';
// import { Suspense } from 'react';

export default function App() {
  let routers = createBrowserRouter([
    {
      path: '',
      element: <LayOut />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'home', index: true, element: <Home /> },
        { path: 'about-us', element: <AboutUs /> },
        { path: 'contact-us', element: <ContactUS /> },
        { path: 'terms', element: <Terms /> },
        { path: 'policy', element: <Policy /> },

        { path: 'universities', element: <Universities /> },
        { path: 'courses', element: <Courses /> },
        // { path: 'courses/:universityId', element: <Courses /> },
        { path: 'course/:courseId', element: <Course /> },
        { path: 'chapter/:chapterId', element: <Chapter /> },

        { path: 'register', element: <Register /> },
        { path: 'login', element: <Login /> },

        { path: 'profile', element: <Profile /> },
        { path: 'my-courses', element: <MyCourses /> },
        { path: 'my-orders', element: <MyOrders /> },
        { path: 'my-installments/:type/:id', element: <MyInstallments /> },
        { path: 'my-installments', element: <MyInstallments /> },
        { path: 'my-projects', element: <MyProjects /> },
        { path: 'project/:id', element: <Project /> },
        { path: 'Submission-Files/:id/:projectId', element: <SubmissionFiles /> },
        { path: 'checkout', element: <Cart /> },
        { path: 'payment-result', element: <PaymentResult /> },
        { path: '*', element: <Notfound /> },
      ],
    },
  ]);

  return (
    <>
        <LoaderProvider>
          <LanguageProvider>
            <ApiDataProvider>
              <UserProvider>
                <CartProvider>
                  <RouterProvider router={routers} />
                </CartProvider>
              </UserProvider>
            </ApiDataProvider>
          </LanguageProvider>
        </LoaderProvider>
    </>
  );
}