import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './NavBar';
import Footer from './Footer';
import Loader from './Loaders/Loader';


export default function LayOut() {
  // let isIww = localStorage.getItem("IWW");
  // if (isIww == null || isIww !== "true")
  //   return (
  //     <>
  //       <Globalpage />
  //     </>
  //   );
  return (
    <>
      <Navbar />
      <div id="convert" style={{ minHeight: '70vh' }}>
        <Loader />
        <Outlet />
      </div>
      {/* <div key={'move-top'} className='move-top'>
        <i className='fas fa-chevron-up'></i>
      </div> */}
      <Footer />
    </>
  );
}
